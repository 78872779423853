// import '../fake-db';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import routes from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../app.css';
import '../print.css';
import { Store } from './redux/Store';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
// import 'react-image-crop/dist/ReactCrop.css';
import { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
const App = () => {
  const content = useRoutes(routes);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    });
  }, []);
  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the installation prompt');
        } else {
          console.log('User dismissed the installation prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (deferredPrompt) {
        handleClickOpen();
      }
    }, 200);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [deferredPrompt]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Provider store={Store}>
      <SettingsProvider>
        <MatxTheme>
          <AuthProvider>{content}</AuthProvider>
        </MatxTheme>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          // theme="light"
          theme="colored"
        />
        {/* <button onClick={handleClickOpen} style={{ display: deferredPrompt ? 'block' : 'none' }}>
          Install App
        </button> */}

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Install the PWA App</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Click the "Install" button below to add this app to your home screen.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleInstallClick} color="primary">
              Install
            </Button>
          </DialogActions>
        </Dialog>
      </SettingsProvider>
    </Provider>
  );
};

export default App;
